import './footer.scss'

function Footer() {
    
    return (
        <footer>
           <p>Alexandre Pauc-Detoc</p>
           <p> &#xA9; Copyright 2023</p>
        </footer>
    )
};

export default Footer;